import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { translate } from '@ngneat/transloco';
import { Navigation } from 'app/core/navigation/navigation.types';
import { StorageUtilsService } from 'app/services/storage-utils/storage-utils.service';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { PermissionsService } from '../permissions/permissions.service';
import { Constants } from '../data/constants';

@Injectable({providedIn: 'root'})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient, 
        private _storageUtilsService:StorageUtilsService,
        private _permissionsService: PermissionsService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) =>
            {
                navigation.default.forEach((nav:FuseNavigationItem)=>{
                    /** check permissions */
                    switch(nav.moduleCodeName) {
                        case 'Users' : {
                            nav.hidden = () => !(this._permissionsService.hasRoleAccess({roleRequired: ['ROLE_ADMIN']}));
                        }
                    }
                    /** translate nav & nav-children titles */
                    nav.title = translate(nav.title,)
                    if(nav?.children?.length>0){
                        nav?.children?.forEach((childNavItem)=>{
                            childNavItem.title = translate(childNavItem.title)
                        })
                    }
                })
                navigation.horizontal = navigation.default;

                this._navigation.next(navigation);
            }),
        );
    }
}
