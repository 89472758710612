import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { Constants } from 'app/core/data/constants';
import { map, of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).checkIsLoggedIn().pipe(
        map((isLogged) => isLogged
        ? true
        : router.createUrlTree([Constants.LOGIN_ROUTE]))
    );
};
