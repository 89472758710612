import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { Constants } from './core/data/constants';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: Constants.MISSIONS_ROUTE },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: Constants.MISSIONS_ROUTE },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: Constants.FORGOT_ROUTE, loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: Constants.LOGIN_ROUTE, loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule) },
            { path: Constants.RESET_PASSWORD_ROUTE, loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then((m) => m.ResetPasswordModule) },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path:Constants.MISSIONS_ROUTE, loadChildren: () => import('app/modules/pages/missions/missions.module').then((m) => m.MissionsModule) },
            { path:Constants.CANDIDATES_ROUTE, loadChildren: () => import('app/modules/pages/candidates/candidates.module').then((m) => m.CandidatesModule) },
            { path:Constants.PROFILE_ROUTE, loadChildren: () => import('app/modules/pages/profile/profile.routes') },
            { path:Constants.USERS_ROUTE, loadChildren: () => import('app/modules/pages/users/users.module').then((m)=>m.UsersModule) },
            { path:Constants.RETURN_TIME_FORM_ROUTE, loadChildren: () => import('app/modules/pages/time-return-form/time-return-form.routes')},
        
        ]
    }
];
