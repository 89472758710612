import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { httpInterceptor } from './http.interceptor';

export const provideHttpInterceptor = (): Array<Provider | EnvironmentProviders> => {
    return [
        MatSnackBar,
        provideHttpClient(withInterceptors([httpInterceptor])),
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(AuthService),
            multi: true,
        },
    ];
};
