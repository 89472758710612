import { Constants } from 'app/core/data/constants';
import { Injectable, inject } from '@angular/core';
import { StorageUtilsService } from 'app/services/storage-utils/storage-utils.service';

export type ROLES = 'ROLE_USER' | 'ROLE_ADMIN';
export type ModuleCodeName = 'Users' | 'Candidates' | 'Missions';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private _storageUtilsService = inject(StorageUtilsService);


  /** check role if has access */
  hasRoleAccess({ roleRequired } : { roleRequired: ROLES[]   }): boolean {
    const tokenDecoded = this._storageUtilsService.getDecodedAccessToken();
    const user = this._storageUtilsService.getUser();
    return tokenDecoded && (roleRequired.some((roleToCheckElemnt) => user?.authorities?.indexOf(roleToCheckElemnt) !== -1));
  }
}
