import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'app/shared/angular-material/angular-material.module';

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [CommonModule,AngularMaterialModule],
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

}
