
export class Constants {
    static ACCESS_TOKEN = 'accessToken';
    static EMAIL = 'email';
    /* routes  names */
    static LOGIN_ROUTE = 'sign-in';
    static LOGOUT_ROUTE = 'sign-out';
    static FORGOT_ROUTE = 'forgot-password';
    static RESET_PASSWORD_ROUTE = 'reset-password';
    static DASHBOARD_ROUTE = 'dashboard';
    static REDIRECTION_ROUTE = 'redirection';
    static REDIRECTION_AUTH_ROUTE = 'redirection-auth';
    static ACCOUNT_ACTIVATION_ROUTE = 'account-activation';
    static MISSIONS_ROUTE = 'missions';
    static CREATE_MISSION_ROUTE = 'create-mission';
    static VIEW_MISSION_ROUTE = 'view-mission';
    static EDIT_MISSION_ROUTE = 'edit-mission';
    static RETURN_TIME_FORM_ROUTE = 'time-return-form';
    static CANDIDATES_ROUTE = 'candidates';
    static CREATE_CANDIDATE = 'create-candidate';
    static VIEW_CANDIDATE = 'view-candidate';
    static EDIT_CANDIDATE = 'edit-candidate';
    static PROFILE_ROUTE = 'profile';
    static USERS_ROUTE = 'users';
    static OP_USER_ROUTE = 'op-user';
    static ABOUT_US_ROUTE = 'about-us';
    static CONTACT_US_ROUTE = 'contact-us';
    static PRIVACY_POLICY_ROUTE = 'privacy-policy';
    static POLITICS_ROUTE = 'politics';
    static NOT_FOUND_ROUTE = 'not-found';
    static STATUS_FILTER = 'status-filter';
    static RANGE_FILTER = 'range-filter';
    static SKILLS_FILTER = 'skills-filter';
    static AVAILABILITY_FILTER = 'availability-filter';
    static COST_FILTER = 'cost-filter';
    static DATE_TIME_FILTER = 'date-time-filter';
    /* filters names */
    /* snackbar messages */
    static SNACKBAR_SUCCESS = 'success-message-snackbar';
    static SNACKBAR_ERROR = 'error-snackbar';
    static SNACKBAR_INFO = 'info-message-snackbar';
    static HOME_PAGE_OFFERS = 'home-page-offers';
    static SEARCH_PAGE_OFFERS = 'search-page-offers';
    static USER_INFO = 'user-info';
    /* actions */
    static LOGIN_ACTION = '[Login] Login';
    static LOGIN_LOADING_ACTION = '[Login] Login loading';
    static LOGIN_SUCCESS_ACTION = '[Login] Login Success';
    static LOGIN_FAILURE_ACTION = '[Login] Login Failure';
    static LOGOUT_ACTION = '[Logout] Logout';
    static SET_UPSERT_MISSION_FORM = '[Missions] Upsert mission form values';
    static SET_CANDIDATES_LIST_MISSION_FORM = '[Missions] Set candidates list';
    static TOGGLE_CANDIDATE = '[Candidate] Toggle candidate item';
    static UPDATE_MISSION_CANDIDATES_ACTION = '[Mission] Update mission candidates';


    /** http headers names */
    static RESPONSE_TOTAL_ITEMS = 'X-Total-Count';

    /* method to encode query params */
    static encode({ valueToEncode }: { valueToEncode: any }): string {
        return btoa(valueToEncode);
    }
    /* method to decode query params */
    static decode({ valueToDecode }: { valueToDecode: string }): any {
        return atob(valueToDecode);
    }
}
