import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AuthService } from "app/core/auth/auth.service";
import { StorageUtilsService } from "app/services/storage-utils/storage-utils.service";
import { login, loginFailure, loginLoading, loginSuccess, logout } from "../actions/authentication.actions";
import { catchError, finalize, map, mergeMap, of, tap } from "rxjs";
import { Router } from "@angular/router";
import { Constants } from "app/core/data/constants";

@Injectable()
export class AuthenticationEffect {
    constructor(
        private actions$: Actions,
        private _store: Store,
        private _authService: AuthService,
        private _storageUtilsService: StorageUtilsService,
        private _router: Router,
    ) { }

    login$ = createEffect(() => this.actions$.pipe(
        ofType(login),
        mergeMap(
            ({ username, password, rememberMe }) => this._authService.signIn({ credentials: { username, password, rememberMe } }).pipe(
                tap((response) => { this._storageUtilsService.setAccessToken(response.id_token) }),
                map((response) => loginSuccess({ accessToken: response.id_token })),
                catchError((error) => of(loginFailure({ loginError: error }))),
                finalize(() => this._store.dispatch(loginLoading({ isLoading: false })))
            )
        )
    )
    );
    logout$ = createEffect(()=>this.actions$.pipe(
        ofType(logout),
        tap(({redirect})=>{
            this._storageUtilsService.removeAccessToken();
            if(redirect){
                this._router.navigate([Constants.LOGIN_ROUTE])
            }
        }),
        catchError((error)=>{
            console.log('logout error ', error)
            return of(error);
        })
    ),{dispatch:false}
    )
}
