import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { map, Observable, ReplaySubject, retry, tap } from 'rxjs';
import { UserModel } from '../models/user.model';
import { DataLogicService } from 'app/services/data-logic/data-logic.service';
import { environment } from 'environments/environment';
import { StorageUtilsService } from 'app/services/storage-utils/storage-utils.service';

@Injectable({providedIn: 'root'})
export class UserService
{
    private _user: ReplaySubject<UserModel> = new ReplaySubject<UserModel>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: DataLogicService<UserModel>,
        private _storageUtilsService : StorageUtilsService
        )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: UserModel)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<UserModel>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<UserModel>
    {
    const url = environment.ENDPOINTS.ACCOUNT_URL;
        return   this._userService.getSingleData({
            url: url,
          }).pipe(
            tap((account: UserModel) => {
                this._storageUtilsService.setUser(account);
                this._user.next(account);
            }),
            retry({count: 2, delay: 2000})
          )
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: UserModel): Observable<any>
    {
        return this._httpClient.patch<UserModel>('api/common/user', {user}).pipe(
            map((response) =>
            {
                this._user.next(response);
            }),
        );
    }
}
