import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'app/core/auth/auth.service';
import { CommonService } from 'app/services/common/common.service';
import { StorageUtilsService } from 'app/services/storage-utils/storage-utils.service';
import { logout } from 'app/store/actions/authentication.actions';
import { catchError, Observable, retry, throwError } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const httpInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const _authService = inject(AuthService);
    const _router = inject(Router);
    const _storageUtilsService = inject(StorageUtilsService);
    const _commonService = inject(CommonService);
    const _store = inject(Store);

    let modified = request;
    if (_storageUtilsService.getAccessToken()) {
        modified = request.clone({ setHeaders: { Authorization: 'Bearer ' + _storageUtilsService.getAccessToken() } });
    }
    return next(modified).pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {
            if (!(error.error instanceof ErrorEvent)) {

                switch (error.status) {
                    case 400:
                        // if (error.error && error.error.errorKey) {
                        //     showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey,commonService:_commonService })
                        // } 
                        if (error.error && error.error.message) {
                            showErrorSnackbar({ errorMessage: 'errors.' + error.error.message,commonService:_commonService })
                        } 
                        else {
                            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage400',commonService:_commonService  })
                        }
                        break;

                    case 401:
                        // _authService.signOut();
                        // _router.navigate(['sign-in']);
                        if (error.error && error.error.errorKey ) {
                            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage401' , commonService: _commonService })
                          } else {
                            showErrorSnackbar({ errorMessage: 'errors.bad_credentials', commonService: _commonService })
                          }
                          _store.dispatch(logout({redirect:true}));
                        break;
                    case 403:
                        if (error.error && error.error.errorKey) {
                            showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey,commonService:_commonService  })
                        } else {
                            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage403',commonService:_commonService  })
                        }
                        break;
                    case 404:
                        if (error.error && error.error.errorKey) {
                            showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey,commonService:_commonService  })
                        } else {
                            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage404',commonService:_commonService  })
                        }
                        break;

                    case 500:
                        showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage500',commonService:_commonService  })
                        break;
                    case 502:

                        break;
                    case 504:
                        if (error.error && error.error.errorKey) {
                            showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey,commonService:_commonService  })
                        } else {
                            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage504',commonService:_commonService  })
                        }
                        break;
                    default:
                        break;
                }
            }
            return throwError(error);
        })
    );
}

const showErrorSnackbar = ({ errorMessage,commonService }: { errorMessage: string, commonService:CommonService }) => {
    commonService.showSnackBar({ message: errorMessage, snackBarType: commonService.errorMessage, });
}


