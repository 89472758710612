import { APP_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, Provider } from '@angular/core';
import { translate, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { StorageUtilsService } from 'app/services/storage-utils/storage-utils.service';


export const provideTransloco = (): Array<Provider | EnvironmentProviders> => {
    return [
        importProvidersFrom(TranslocoModule),
        {
            // Provide the default Transloco configuration
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [
                    // {
                    //     id: 'en',
                    //     label: 'languages.english',
                    // },
                    {
                        id: 'fr',
                        label: 'languages.french',
                    },
                    // {
                    //     id: 'de',
                    //     label: 'languages.deutch',
                    // },
                ],
                defaultLang: 'fr',
                fallbackLang: 'fr',
                reRenderOnLangChange: true,
                prodMode: true,
            }),
        },
        {
            // Provide the default Transloco loader
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader,
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            useFactory: () => {
                const _storageUtilsService = inject(StorageUtilsService);
                const translocoService = inject(TranslocoService);
                const defaultLang = _storageUtilsService.getLang() ?? translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => translocoService.load(defaultLang).toPromise();
            },
            multi: true,
        },
    ];
};
