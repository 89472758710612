
export interface AuthenticationState {
    isLoggedIn: boolean;
    accessToken: string | null;
    loginError:string | null;
    isLoading:boolean,
}

export const initialAuthenticationState : AuthenticationState = {
    isLoggedIn: false,
    accessToken: null,
    loginError : null,
    isLoading : false,
}
