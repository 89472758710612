import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { AuthenticationState } from "../states/authentication.state";
import { authenticationReducer } from "./authentication.reducers";
import { MissionState } from "../states/mission.state";
import { missionReducer } from "./mission.reducers";

export interface AppState {
    authentication : AuthenticationState,
    missions: MissionState
}

export const reducers : ActionReducerMap<AppState> = {
    authentication : authenticationReducer,
    missions: missionReducer,
}


export const selectAuthentication = createFeatureSelector<AuthenticationState>('authentication');
export const selectMissions = createFeatureSelector<MissionState>('missions');
