/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Constants } from 'app/core/data/constants';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'planning',
        title: 'nav.planning',
        type : 'basic',
        icon : 'list_alt',
        link : `/${Constants.MISSIONS_ROUTE}`,
        moduleCodeName: 'Missions',
    },
    {
        id   : 'candidate_list',
        title: 'nav.candidate_list',
        type : 'basic',
        icon : 'people',
        link : `/${Constants.CANDIDATES_ROUTE}`,
        moduleCodeName: 'Candidates',
    },
    {
        id   : 'users_management',
        title: 'nav.users_management',
        type : 'collapsable',
        icon : 'manage_accounts',
        moduleCodeName: 'Users',
        children: [
            {
                id   : 'op_user',
                title: 'nav.op_user',
                type : 'basic',
                icon : 'person_outline',
                link : `/${Constants.USERS_ROUTE}/${Constants.OP_USER_ROUTE}`,
            }
        ]
    },  
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;