import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { Constants } from 'app/core/data/constants';
import { map, of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).checkIsLoggedIn().pipe(
        map((isLogged) => isLogged
        ? router.createUrlTree([Constants.MISSIONS_ROUTE])
        : true
      )
    );
};
