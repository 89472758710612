import { createReducer, on } from "@ngrx/store";
import { initialAuthenticationState } from "../states/authentication.state";
import { login, loginFailure, loginLoading, loginSuccess, logout } from "../actions/authentication.actions";



export const authenticationReducer = createReducer(
    initialAuthenticationState,
    on(login, state => ({ ...state, isLoggedIn: false, accessToken: null, loginError: null })),
    on(loginLoading, (state, { isLoading }) => ({ ...state, isLoading })),
    on(loginSuccess, (state, { accessToken }) => ({ ...state, accessToken, isLoggedIn: true })),
    on(loginFailure, (state, { loginError }) => ({ ...state, loginError, isLoggedIn: false })),
    on(logout, (state) => ({ ...state, accessToken: null, isLoggedIn: false }))
)
