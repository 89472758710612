import { ElementRef, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@ngneat/transloco';
import { CustomizedSnackbarComponent } from 'app/shared/components/customized-snackbar/customized-snackbar.component';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(
        private _snackBar: MatSnackBar,
        private fileSaverService: FileSaverService

    ) { }
    successMessage = 'success-message-snackbar';
    errorMessage = 'error-snackbar';
    infoMessage = 'info-message-snackbar';

    showSnackBar({ message, snackBarType, disableTranslate }: { message: string, snackBarType: string, disableTranslate?: boolean }): void {

        this._snackBar.openFromComponent(CustomizedSnackbarComponent, {

            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: [snackBarType], // .success-message-snackbar .error-snackbar .info-message-snackbar
            duration: 5000,

            data: {
                snackBarType,
                msg: disableTranslate ? message : translate(message),
            }
        });
    }
    /** data cloner method */
    cloneData = ({ dataToClone }: { dataToClone: any }) => cloneDeep(dataToClone);

    /** download file */
    saveFile(file, fileName): void {
        this.fileSaverService.save(file, fileName);
    }

    /** get start & end time value parsed */
    timeParserToMoment({ time }: { time: string | moment.Moment | any }) {
        if (time instanceof moment) {
            return time
        } else {
            return this.timeSplitParseMeth({ time: time ?? "11:00" });
        }
    }

    /** set time */
    timeSplitParseMeth({ time }: { time?: string }): moment.Moment {
        const timeNowMoment = moment(new Date());
        // set the time using a string "HH:mm"
        const [hours, minutes] = time.split(':');
        timeNowMoment.set({
            hour: parseInt(hours, 10),
            minute: parseInt(minutes, 10),
            second: parseInt("0", 10)
        })
        return timeNowMoment;
    }

    // Function to scroll to the first invalid control
    scrollToInvalidControl({ formgroupToCheck, elementRef }: { formgroupToCheck: FormGroup, elementRef: ElementRef<HTMLFormElement> }): void {
        const invalidControlElement = this.findInvalidControl(formgroupToCheck, elementRef);

        if (invalidControlElement) {
            invalidControlElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    // Function to find the first invalid control
    findInvalidControl(formGroup: FormGroup, elementRef: ElementRef<HTMLFormElement>): any | null {
        const formKeys = Object.keys(formGroup.controls);
        const element = elementRef.nativeElement;
        for (const controlKey of formKeys) {
            const control = formGroup.get(controlKey);

            // If the control is invalid, return its associated HTML element
            if (control.invalid) {
                if (control instanceof FormControl) {
                    return element.querySelector(`[formcontrolname="${controlKey}"]`) ?? element.querySelector(`[ng-reflect-name="${controlKey}"]`)
                } else if (control instanceof FormGroup) {
                    return element.querySelector(`[formgroupname="${controlKey}"]`);
                } else if (control instanceof FormArray) {
                    return element.querySelector(`[formarrayname="${controlKey}"]`);
                }
            }

            // If it's a nested form group, recursively search for the first invalid control
            if (control instanceof FormGroup) {
                const nestedInvalidControl = this.findInvalidControl(control, elementRef);
                if (nestedInvalidControl) {
                    return nestedInvalidControl;
                }
            }
        }
        return null;
    }


}
