import { createReducer, on } from "@ngrx/store";
import { initialMissionState } from "../states/mission.state";
import { setCandidatesListForUpsertMission, setUpsertMissionForm, toggleCandidate } from "../actions/mission.actions";


export const missionReducer = createReducer(
    initialMissionState,
    on(setUpsertMissionForm,(state,{upsertMissionForm})=> ({...state,upsertMissionForm})),
    on(setCandidatesListForUpsertMission,(state,{candidatesListForUpsertMission})=>({...state,candidatesListForUpsertMission})),
    on(toggleCandidate,(state, {toggledList}) => {
        const oldCandidatesList = [...state.candidatesListForUpsertMission];

        toggledList.forEach((toggledItem)=>{
            const index = oldCandidatesList.findIndex((candidate) => candidate.id === toggledItem.object.id);
            if(index !== -1){
                oldCandidatesList.splice(index,1);
            }else{
                oldCandidatesList.push(toggledItem.object);
            }
        })
        return {...state,candidatesListForUpsertMission: oldCandidatesList};
    })
);
