import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { StorageUtilsService } from './services/storage-utils/storage-utils.service';
import { loginSuccess } from './store/actions/authentication.actions';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(
        private _store :Store,
        private _storageUtilsService: StorageUtilsService,
    )
    {
    }
    ngOnInit(): void {
        const accessToken = this._storageUtilsService.getAccessToken();
        if(accessToken){
            this._store.dispatch(loginSuccess({accessToken}));
        }
    }
}
