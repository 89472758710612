const API_URL = '/api';

export const environment = {
    production: true,
    ENDPOINTS: {
        LOGIN_URL: `${API_URL}/authenticate`,
        REGISTER_CLIENT_URL: `${API_URL}/admin/admin-clients/register`,
        FORGOT_PASSWORD_URL: `${API_URL}/account/reset-password/init`,
        RESET_PASSWORD_URL: `${API_URL}/account/reset-password/finish`,
        FINISH_PASSWORD_URL: `${API_URL}/account/reset-password/finish`,
        ACCOUNT_ACTIVATION: `${API_URL}/activate`,
        MISSIONS_URL: `${API_URL}/missions`,
        MISSION_REFERENCE_URL: `${API_URL}/missions/generate-ref`,
        CANDIDATES_URL: `${API_URL}/candidates`,
        CANDIDATES_REFERENCE_URL: `${API_URL}/candidates/generate-ref`,
        USERS_URL: `${API_URL}/admin/users`,
        USERS_REFERENCE_URL: `${API_URL}/admin/users/generate-ref`,
        ACCOUNT_URL: `${API_URL}/account`,
        CHANGE_PASSWORD_URL: `${API_URL}/account/change-password`,
        TIME_RETURN_FORM_URL: `${API_URL}/hours-return-forms`,
        CLIENTS_URL: `${API_URL}/clients`,
        COST_CENTERS_URL: `${API_URL}/cost-centers`,
        RAYONS_URL: `${API_URL}/rayons`,
        SKILLS_URL: `${API_URL}/candidate-skills`,
    }
};
