import { UpsertMissionModel } from "app/core/models/upsert-mission.model";

export interface MissionState {
    upsertMissionForm:UpsertMissionModel;
    candidatesListForUpsertMission:any,
}

export const initialMissionState : MissionState = {
    upsertMissionForm : null,
    candidatesListForUpsertMission:[],
}
