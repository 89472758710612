import { Injectable } from '@angular/core';
import { UserModel } from 'app/core/models/user.model';
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class StorageUtilsService {

    constructor() { }
    setAccessToken(token: any) {
        localStorage.setItem('accessToken', token);
    }

    getAccessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /** get decode access token */
    getDecodedAccessToken(): any {
        try {
            return jwt_decode(localStorage.getItem('accessToken'));
        } catch (error) {
            return null;
        }
    }

    removeAccessToken() {
        localStorage.removeItem('accessToken');
    }

    setUser(user: any): void {
        localStorage.setItem('user', JSON.stringify(user));
    }

    getUser(): UserModel | null {
        const itemString = localStorage.getItem('user');
        return itemString ? JSON.parse(itemString) : null;
    }

    removeUser(): void {
        localStorage.removeItem('user');
    }


    setLang(lang: string): void {
        localStorage.setItem('lang',lang );
    }

    getLang(): any {
        const itemString = localStorage.getItem('lang');
        return itemString ?? null;
    }

    removeLang(): void {
        localStorage.removeItem('lang');
    }


}
