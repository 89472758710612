<ng-container *transloco="let translate">
    <!-- Button -->
    <button mat-icon-button [matMenuTriggerFor]="userActions">
        <span class="relative">
            <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user?.avatar" [src]="user?.avatar">
            <mat-icon *ngIf="!showAvatar || !user?.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>

        </span>
    </button>

    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>{{translate('signed_in_as')}}</span>
                <span class="mt-1.5 text-base font-semibold">{{user?.email}}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="navigateToProfile()">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>{{translate('profile')}}</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
            <span>{{translate('logout')}}</span>
        </button>
    </mat-menu>
</ng-container>